<header>
    <div class="mini-navbar-wrap">
        <div class="logo-wrap">
            <p class="logo"><span class="logo">SoftUni Forum</span></p>
        </div>
        <div class="mini-navbar" *ngIf='!isLogged'>
            <ul>
                <li>
                    <a href="#" (click)='loginHandler()'>Login</a>
                </li>
                <li>
                    <a href="#">Register</a>
                </li>
            </ul>
        </div>
        <div class="mini-navbar" *ngIf='isLogged'>
            <ul>
                <li>
                    <div>Hello, [username]@localdomain.com</div>
                </li>
                <li>
                    <a href="#" (click)='logoutHandler()'>Logout</a>
                </li>
            </ul>
        </div>
    </div>
    <nav>
        <ul>
            <li>
                <a href="#">Home</a>
            </li>
            <li>
                <a href="#">Themes</a>
            </li>
            <li>
                <a href="#">New Theme</a>
            </li>
        </ul>
    </nav>
</header>
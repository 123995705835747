import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ITheme } from '../interfaces';
import { ThemeService } from '../theme.service';

@Component({
  selector: 'app-theme-list',
  templateUrl: './theme-list.component.html',
  styleUrls: ['./theme-list.component.css']
})
export class ThemeListComponent implements OnInit, AfterViewInit {

  themeList: ITheme[];

  constructor(private themeService: ThemeService) { }

  ngOnInit(): void {
    this.themeService.loadThemeList().subscribe(x => { this.themeList = x; } );
  }

  ngAfterViewInit(): void{
    // console.log('View was initialized');
  }

}

<div *ngIf='postList' class="wrapper">
    <div *ngFor='let post of postList' class="aside-theme">
        <a href="#">
            <h3>{{post.themeId.themeName}}</h3>
            <p>{{post.userId.username}}</p>
            <p><span>{{post.created_at}}</span></p>
        </a>
    </div>
    <div *ngIf='postList.length===0'>No Posts!</div>
</div>
<div *ngIf='!postList' class="wrapper">Loading...</div>